// @font-face {
//     font-family: 'Nanum Gothic';
//     font-style: normal;
//     font-weight: 400;
//     src: local('NanumGothic'),
//         url('../assets/font/nanum-gothic-v14-regular.woff') format('woff'); /* Modern Browsers */
// }

// @font-face {
//     font-family: 'Nanum Gothic';
//     font-style: normal;
//     font-weight: 700;
//     src: local('NanumGothic Bold'), local('NanumGothic-Bold'),
//         url('../assets/font/nanum-gothic-v14-700.woff') format('woff'); /* Modern Browsers */
// }

// @font-face {
//     font-family: 'Nanum Gothic';
//     font-style: normal;
//     font-weight: 800;
//     src: local('NanumGothic ExtraBold'), local('NanumGothic-ExtraBold'),
//         url('../assets/font/nanum-gothic-v14-800.woff') format('woff'); /* Modern Browsers */
// }

// @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,700&display=swap&subset=korean');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    font-size: 10px;
    overflow: hidden;
}

body {
    // font-family: 'Nanum Gothic', sans-serif !important;
    background-color: #f5f7fb;
    color: #242424;
    // font-family: 'Noto Sans KR', sans-serif;
    // min-width: 1180px;
    // max-width: 1920px;
}

ul,
li {
    list-style: none;
}

a,
button {
    text-decoration: none;
    // font-family: 'Noto Sans KR', sans-serif;
}

#root {
    height: 100%;
}

#App {
    height: 100%;
}

i {
    vertical-align: middle;

    &.none-middle {
        vertical-align: baseline;
    }
}

// prime ui style

.p-calendar,
.p-autocomplete,
.p-dropdown,
.p-inputtext {
    width: 100%;
}

.p-inputtextarea {
    resize: none;
}

.clear-both:after {
    content: '';
    display: block;
    clear: both;
}

.text {
    &-error {
        color: #dc1922;
    }

    &-description {
        color: #747474;
    }
}

.column {
    @for $i from 1 to 25 {
        &.col-#{$i} {
            width: 100% / (24 / $i);
        }
    }

    &.col-fill {
        flex: 1;
    }
}

@for $j from 0 to 21 {
    .pd-#{$j} {
        padding-left: $j * 2px;
        padding-right: $j * 2px;
    }
}

@for $j from 0 to 21 {
    .pd-h#{$j} {
        padding-top: $j * 2px;
        padding-bottom: $j * 2px;
    }
}

.DayPicker {
    font-size: 1.3rem;

    .DayPicker-Day {
        padding: 0.55em 0.65em;
    }

    * {
        outline: none;
    }

    &.Range
        .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: #f0f8ff !important;
        color: #4a90e2;
    }

    &.Range .DayPicker-Day {
        border-radius: 0 !important;
    }
}

body .p-inputtext {
    font-size: 1.3rem;
    height: 42px;
    line-height: 42px;
    color: #262626;
    outline: none;
    background: #ffffff;
    padding: 0 1.6rem;
    border: 1px solid #dedede;
    border-radius: 3px;
    transition: border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    outline: none;
}

body .p-calendar.small .p-inputtext {
    font-size: 1.2rem;
    height: 38px;
    line-height: 38px;
}
